
import { dateFrancaise, sqlDate, variables } from "@/plugins/variables"
import { Machine, Pneu, PneuHistorique, TypeMachine } from "@/store/types"
import axios from "axios"
import Vue from "vue"
import Saisirdate from "../components/SaisirDate.vue"
import NonAcces from "../components/NonAcces.vue"
import SchemasPneu from "../components/ShemasPneu.vue"

export default Vue.extend({
  name: "Machine",
  components: { Saisirdate, NonAcces, SchemasPneu },
  data() {
    return {
      unsubscribe: null as any,
      machines: [] as Machine[],
      machine: new Machine(),
      pneus: [] as Pneu[],
      listePneu: [] as any[],
      pneuChoisie: [] as Pneu[],
      machineTampon: new Machine(),
      emplacementPneu: [] as string[],
      dictionnaire: [] as string[],
      dimensionPneu: "",
      lesDimensionPneu: [] as string[],
      typeMachine: [] as TypeMachine[],
      listeTypeMachine: [] as any[],
      groupeSelect: ["C", "CAD", "PS"],
      dialogueAjout: false,
      unPneu: new Pneu(),
      etatPossible: [] as any[],
      dialogueNouveauPneu: false,
      snakbar_text: "",
      snak_visible: false
    }
  },
  methods: {
    initialiser() {
      axios
        .get(this.$store.state.ajaxurl + "Machine.php", {
          params: {
            action: "INITIALISER_MACHINE"
          }
        })
        .then(response => {
          if (response && response.data.machines) {
            this.machines = response.data.machines.map((m: Machine) => {
              return new Machine(m)
            })
          }
          if (response && response.data.pneus) {
            this.pneus = response.data.pneus.map((p: Pneu) => {
              return new Pneu(p)
            })
            for (const unPneu of this.pneus) {
              const existe = this.lesDimensionPneu.find((d: string) => d == unPneu.getDimension())
              if (existe === undefined) {
                this.lesDimensionPneu.push(unPneu.getDimension())
              }
            }
            this.gestionDimensionPneu()
          }
          if (response.data.typeMachine) {
            this.typeMachine = response.data.typeMachine.map((t: TypeMachine) => {
              return new TypeMachine(t)
            })
          }
          this.choixLangue(this.$store.state.langue)
        })
    },
    ouvrirNouvelleMachine() {
      this.machine = new Machine()
      this.machine.setDateEntree(sqlDate(new Date()))
      this.machine.setDateSortie("")
      this.dialogueAjout = true
      this.gestionDimensionPneu()
      this.$store.commit("majPneu", { lesEmplacement: "", etat: "nouvelle machine" })
    },
    gestionDimensionPneu() {
      if (this.dimensionPneu == null) {
        this.dimensionPneu = ""
      }
      this.listePneu = this.pneus.map((p: Pneu) => {
        if (p.getMonteOu() == null) {
          return { value: p, text: p.getCode() + " " + p.getDimension() }
        } else if (this.machine.getId() > 0) {
          const unPneu = this.machine.getPneu().find((pn: Pneu) => pn.getId() == p.getId())
          if (unPneu !== undefined) {
            return { value: unPneu, text: unPneu.getCode() + " " + unPneu.getDimension() }
          }
        }
      })
      this.listePneu.sort((a: any, b: any) => {
        if (a.value.sp_dimension == this.dimensionPneu && b.value.sp_dimension != this.dimensionPneu) return -1
        if (a.value.sp_dimension != this.dimensionPneu && b.value.sp_dimension == this.dimensionPneu) return 1
        else return 0
      })
    },
    async modifierMachine(sm_id: number) {
      const laMachine = this.machines.find((m: Machine) => m.getId() == sm_id)
      this.listePneu = this.pneus.map((p: Pneu) => {
        if (p.getMonteOu() == null) {
          return { value: p, text: p.getCode() }
        }
      })
      if (laMachine !== undefined) {
        this.machine = laMachine
        this.machineTampon = new Machine(laMachine)
        if (this.machine.getPneu().length != 0) {
          let emplacementPneu = this.machine.getPneu()[0].getMonteOu()
          for (let i = 1; i < this.machine.getPneu().length; i++) {
            emplacementPneu += " " + this.machine.getPneu()[i].getMonteOu()
          }
          this.pneuChoisie = this.machine.getPneu()
          for (const unPneu of this.pneuChoisie) {
            this.listePneu.push({ value: unPneu, text: unPneu.getCode() + " " + unPneu.getDimension() })
          }
          this.$store.commit("majPneu", { lesEmplacement: emplacementPneu, etat: "modifierMachine" })
        }
        this.dialogueAjout = true
      } else {
        this.snackbarVisible("Une erreur est survenue")
      }
    },
    fermerNouvelleMachine() {
      const laMachine = this.machines.findIndex((m: Machine) => m.getId() == this.machineTampon.getId())
      if (laMachine != -1) {
        this.machines[laMachine] = this.machineTampon
        this.machineTampon = new Machine()
      }
      this.dialogueAjout = false
      this.$store.commit("majPneu", { lesEmplacement: "", etat: "FermerNouvelleMachine" })
      this.dimensionPneu = ""
    },
    attributSm_id() {
      let aDepacer = 0
      for (const uneMachine of this.machines) {
        if (aDepacer <= uneMachine.getId()) {
          aDepacer = uneMachine.getId() * 1 + 1 * 1
        }
      }
      return aDepacer
    },
    choixPneu(pneuChoisie: Pneu, index: number) {
      pneuChoisie.setMonteOu(this.emplacementPneu[index])
    },
    supprimerMachine() {
      axios
        .post(this.$store.state.ajaxurl + "Machine.php", {
          action: "SUPPRIMER_MACHINE",
          sm_id: this.machine.getId()
        })
        .then(() => {
          this.dialogueAjout = false
          this.snackbarVisible("La machine a bien été supprimer")
          this.initialiser()
        })
    },
    enregistrerMachine() {
      let existe = true
      let pneuAssigner = true
      for (const unPneu of this.pneuChoisie) {
        if (unPneu.getId() == "") {
          pneuAssigner = false
        }
      }
      if (pneuAssigner == true) {
        if (this.machine.getId() == -1) {
          this.machine.setId(this.attributSm_id())
          for (const unPneu of this.pneuChoisie) {
            unPneu.setIdMachine(this.machine.getId())
          }
          existe = false
        }
        for (const unPneu of this.pneuChoisie) {
          unPneu.pushHistorique(new PneuHistorique(new Date().getTime() + "", unPneu.getId(), this.machine.getId(), unPneu.getMonteOu(), unPneu.getEtat(), "M", sqlDate(new Date()), ""))
        }
        this.machine.setPneu(this.pneuChoisie)
        axios
          .post(this.$store.state.ajaxurl + "Machine.php", {
            action: "ENREGISTRER_MACHINE",
            machine: this.machine
          })
          .then(() => {
            this.dialogueAjout = false
            if (!existe) {
              this.machines.push(this.machine)
            }
            this.machineTampon = new Machine()
            this.dimensionPneu = ""
            this.initialiser()
          })
      } else {
        this.snackbarVisible(this.dictionnaire[14])
      }
    },
    ouvertureDialogueNouveauPneu() {
      this.dialogueNouveauPneu = true
      this.unPneu = new Pneu()
    },
    enregistrerPneu() {
      if (this.unPneu.getId() == "") {
        const laDate = new Date().getTime() + ""
        this.unPneu.setId(laDate)
      }
      axios
        .post(this.$store.state.ajaxurl + "Pneu.php", {
          action: "ENREGISTRER_PNEU",
          pneu: this.unPneu
        })
        .then(() => {
          if (this.dialogueNouveauPneu == true) {
            this.pneus.push(this.unPneu)
          }
          this.dialogueNouveauPneu = false
          this.unPneu = new Pneu()
          this.initialiser()
        })
    },
    fermerNouveauPneu() {
      this.dialogueNouveauPneu = false
      this.unPneu = new Pneu()
    },
    affichageTypeMachine(tm_id: number) {
      const unType = this.typeMachine.find((t: TypeMachine) => t.getId() == tm_id)
      if (unType !== undefined) {
        return unType.getType(this.$store.state.langue)
      }
      return ""
    },
    choixLangue(langue: string) {
      this.listeTypeMachine = this.typeMachine.map((t: TypeMachine) => {
        return { value: t.getId(), text: t.getType(langue) }
      })
      switch (langue) {
        case "FR":
          this.dictionnaire = [
            "Ajouter", //0
            "Immatriculation", //1
            "Désignation", //2
            "Catégorie", //3
            "Modèle", //4
            "Série", //5
            "Nombre de roue", //6
            "Date d'entrée", //7
            "Date de sortie", //8
            "Observation", //9
            "Nouvelle machine", //10
            "Status", //11
            "Code groupe", //12
            "Enregistrer", //13
            "Veuillez attribuer des pneus aux roues.", //14
            "Code", //15
            "Dimension", //16
            "État", //17
            "Nouveau pneu", //18
            "Horamètre", //19
            "Non renseigné", //20
            "Maintenance poussière" //21
          ]
          this.etatPossible = [
            { value: "N", text: "Neuf" },
            { value: "H", text: "HS" },
            { value: "D", text: "Défectueux" },
            { value: "U", text: "Usagé" },
            { value: "C", text: "Réchappé" },
            { value: "R", text: "Réparé" }
          ]

          break
        case "AL":
          this.dictionnaire = [
            "Hinzufügen", //0
            "Kennzeichnung", //1
            "Bezeichnung", //2
            "Kategorie", //3
            "Modell", //4
            "Serie", //5
            "Nummer des Rades", //6
            "Eingang Datum", //7
            "Ausgang Datum", //8
            "Anmerkung", //9
            "Neue Maschine", //10
            "Status", //11
            "Gruppencode", //12
            "Registrieren", //13
            "Bitte ordnen Sie den Rädern Reifen zu.", //14
            "Code", //15
            "Dimension", //16
            "Zustand", //17
            "Neuer Reifen", //18
            "Betriebsstunden", //19
            "Nicht angegeben", //20
            "Wartung Staub" //21
          ]
          this.etatPossible = [
            { value: "N", text: "Neu" },
            { value: "H", text: "Schrott" },
            { value: "D", text: "Defekt" },
            { value: "U", text: "Gebraucht" },
            { value: "C", text: "Runderneuert" },
            { value: "R", text: "Repariert" }
          ]
          break
      }
    },
    snackbarVisible(text: string) {
      this.snakbar_text = text
      this.snak_visible = true
    },
    dateFrancaise(date: string) {
      return dateFrancaise(date)
    },
    autAdministration() {
      return variables.autorisation.includes("Administration")
    },
    autMachine() {
      return variables.autorisation.includes("Gestion machine")
    }
  },
  created() {
    this.initialiser()
    this.choixLangue(this.$store.state.langue)
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
          break
        case "majPneu":
          if (this.$store.state.pneu.lesEmplacement != "" && this.$store.state.pneu.etat != "SANS_PNEU") {
            this.emplacementPneu = this.$store.state.pneu.lesEmplacement.split(" ")
            const lesPneuChoisi: Pneu[] = []
            for (const unEmplacement of this.emplacementPneu) {
              const lesEmplacement = this.pneuChoisie.find((p: Pneu) => p.getMonteOu() == unEmplacement)
              if (lesEmplacement === undefined) {
                const unPneu = new Pneu()
                unPneu.setMonteOu(unEmplacement)
                lesPneuChoisi.push(unPneu)
              } else {
                lesEmplacement.setMonteOu(unEmplacement)
                lesPneuChoisi.push(lesEmplacement)
              }
            }
            this.pneuChoisie = lesPneuChoisi
          } else {
            this.emplacementPneu = []
            this.pneuChoisie = []
          }
          break
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
